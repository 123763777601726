.loader {
    width: 68px;
    height: 68px;
    margin: auto;
    position: relative;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed */
  }
  
  .loader:before {
    content: '';
    width: 68px;
    height: 7px;
    background: #acd1af;
    position: absolute;
    top: 80px;
    left: 0;
    border-radius: 50%;
    animation: shadow324 0.5s linear infinite;
  }
  
  .loader:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #81B622
  ;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    animation: jump7456 0.5s linear infinite;
  }
  
  @keyframes jump7456 {
    15% {
      border-bottom-right-radius: 3px;
    }
  
    25% {
      transform: translateY(9px) rotate(22.5deg);
    }
  
    50% {
      transform: translateY(18px) scale(1, .9) rotate(45deg);
      border-bottom-right-radius: 40px;
    }
  
    75% {
      transform: translateY(9px) rotate(67.5deg);
    }
  
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }
  
  @keyframes shadow324 {
  
    0%,
      100% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.2, 1);
    }
  }