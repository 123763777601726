.siteplan-container {
  position: relative;
  height: 90vh;
  overflow: auto;
}

.tools {
  position: absolute;
  top: 10px;
  right: 10px;
}

.image-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 100%; /* Atur rasio aspek sesuai kebutuhan (misalnya 100% untuk rasio 1:1) */
}

.image-container .siteplan-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(25%, 0); /* Tambahkan transformasi untuk memindahkan gambar ke pojok kanan */
  transform-origin: top right; /* Tetapkan titik asal transformasi di pojok kanan atas */
}

.tooltip {
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  z-index: 100;
  font-size: 12px;
  font-family: Arial, sans-serif;
  line-height: 1.2;
  pointer-events: none;
}

.tooltip-link {
  color: rgb(5, 147, 248);
  text-decoration: underline;
}

.tooltip:before {
  content: "";
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}


.marker {
  position: absolute;
  top: 50%; /* Posisi vertikal relatif terhadap container */
  left: 50%; /* Posisi horizontal relatif terhadap container */
  width: 10px;
  height: 10px;
  /* background-color: red; */
  border-radius: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%); /* Pusatkan marker di tengah */
}

.marker.available {
  background-color: red;
}

.marker.sold {
  background-color:  limegreen;
}
