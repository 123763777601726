.navbar {
  background-color: rgba(68, 68, 68, 0.5);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  position: relative;
  isolation: isolate;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 35px;
  box-sizing: border-box;
  height: auto;
}

.navbarSiteplan {
  background-color: rgb(0, 0, 0, 0.77);
}

  
  .frame5 {
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    margin: 0px;
  }
  
  .theView {
    height: 19.42px;
    width: 109.76px;
    margin: 0px;
  }

  .icRoundArrowBack {
    border-radius: 0px;
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    width: 40px;
    height: 60px;
    margin: 0px;
    overflow: hidden;
    left: -15px;
    top: -15px;
  }
  
  .vector {
    height: 15.17px;
    width: 15.58px;
    position: absolute;
    left: 4px;
    top: 0px;
  }

  /* body {
    background-color: #282828;
  } */
  
  .arrow {
    width: 3vmin; /* Mengubah lebar panah */
    height: 3vmin; /* Mengubah tinggi panah */
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: rotate(225deg);
  
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border-width: .5vmin .5vmin 0 0; /* Mengubah lebar panah */
      border-style: solid;
      border-color: #fafafa;
      transition: 0.2s ease;
      display: block;
      transform-origin: 100% 0;
    }
  
    &:after {
      content: '';
      float: left;
      position: relative;
      top: -100%;
      width: 100%;
      height: 100%;
      border-width: 0 .4vmin 0 0; /* Mengubah lebar panah */
      border-style: solid;
      border-color: #fafafa;
      transform-origin: 100% 0;
      transition: 0.2s ease;
    }
  
    &:hover::after {
      transform: rotate(43deg);
      border-color: #B4D66F;
      height: 120%;
    }
    &:hover::before {
      border-color: #B4D66F;
      transform: scale(0.8);
    }
  }
  
  
  
  .byHektaKarya {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    margin: 4px 0px 0px 0px;
  }
  
  .frame4 {
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1;
    margin: 0px 0px 0px 64px;
  }
  
  .frame3 {
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
    margin: 0px 0px 0px 64px;
  }
  
  .beranda {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    margin: 0px;
  }
  
  .siteplan {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    margin: 0px 0px 0px 32px;
  }
  
  .q3dVirtual {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    margin: 0px 0px 0px 32px;
  }
  
  .about {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    margin: 0px 0px 0px 32px;
  }
  
  .frame2 {
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
    margin: 0px 0px 0px 64px;
  }
  
  .buttonLogin {
    --color: #357E47;
    font-family: inherit;
    display: inline-block;
    width: 8em;
    height: 2.6em;
    line-height: 2.5em;
    margin: 10px;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    font-size: 17px;
    border-radius: 6px;
    font-weight: 500;
    color: var(--color);
   }
   
   .buttonLogin:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
   }
   
   .buttonLogin:hover {
    color: #fff;
   }
   
   .buttonLogin:before {
    top: 100%;
    left: 100%;
    transition: all .7s;
   }
   
   .buttonLogin:hover:before {
    top: -30px;
    left: -30px;
   }
   
   .buttonLogin:active:before {
    background: #357E47;
    transition: background 0s;
   }
  
  .buttonLogin:hover:before {
    left: -30px;
  }
  
  .buttonLogin:active:before {
    background: #95b19b;
    transition: background 0s;
  }

  .lockIcon {
    margin-right: 8px;
  }
  
  